import Vue from "vue";
import VueRouter from "vue-router";
// import Router from "@/router";
import VueCookies from "vue-cookies";
import {
	login,
	wxLogin,
	getAppidByTenantId
} from "@/api/login.js";
import {
	Toast
} from "vant";

//todo 张工模拟登录
// localStorage.setItem("scope", undefined);
// localStorage.setItem("userInfo", '{"uuid":"0A71E3F53F20485EAEF1C5580BB949D8","openId":"olcRP53B8-4KiLWgoWLRTnamWpzc","unionId":null,"profile":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTImLHSGibrIeaCXoXNoA9XTdH5CkeRV5lFE8qiccZ9mnLRT4hzkgpB4D5XN1RY0a49oHSWnL6fKBcfA/132","nick":"衬安。","userType":"student","userId":"5317BEFAEADA452995D0F8B255A6EFFE","tenantId":"000000","platform":"mp","createTime":"2022-10-07T07:32:58.000+00:00","phone":null}');
// localStorage.setItem("tenantId", '000000');
// localStorage.setItem("refresh_token", '61_nYDfVMp3FYA7wkK989MiySgZ0Z43F1FaafwBN7ApQLUq9_bnGnPLKpPsO78c_MhMSycGk3uZab3ht68PyPMoOXzjfZ5xYIt84q-MCDQKSYY');
// localStorage.setItem("appid", 'wxd21e3ea9737083fc');
// localStorage.setItem("access_token", '61_TmW70GanHhmrRlTGtOA4Q0u7kuo-7aSUHZEbwA_wbwzczs4yys7UZkinSMJp_23-4L9Q-X8KRgIhwbc7kPZ_ct0y6j-0Q31bX04krl-cn5c');
// localStorage.setItem("token", 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJJZEVudGl0eSI6InN0YWZmIiwidXVpZCI6IjUzMTdCRUZBRUFEQTQ1Mjk5NUQwRjhCMjU1QTZFRkZFIiwidGVuYW50SWQiOiIwMDAwMDAiLCJ1c2VyTmFtZUlkIjoiNTMxN0JFRkFFQURBNDUyOTk1RDBGOEIyNTVBNkVGRkUiLCJ1c2VyTmFtZSI6IuW8oOW3pSIsIm9yZ0lkIjoiIiwicm9sZUlkIjoiIiwiZXhwIjoxNjY1MzAwNzc4LCJuYmYiOjE2NjUxMjc5Nzh9.vz0IQ1m-S7QW_mnPWoZ_v0lphf6J2_yt3CtwtGpIY3Y');
// VueCookies.set("openId", 'olcRP53B8-4KiLWgoWLRTnamWpzc'); //return this


Vue.use(VueRouter);
const routes = [{
		path: "/",
		redirect: "/home", //重定向
	},
	{
		path: "/login",
		name: "Login",
		meta: {
			keepAlive: false,
			title: "登录"
		},
		component() {
			return import("@/views/login/login.vue");
		},
	},
	{
		path: "/home",
		name: "Home",
		meta: {
			keepAlive: false,
			title: "首页"
		},
		redirect: "/home/home", //重定向
		component() {
			return import("@/views/home/home.vue");
		},
		children: [{
				path: "/home/home",
				name: "homeIndex",
				component: () => import("@/views/home/main.vue"),
				meta: {
					keepAlive: false,
					title: "后勤首页"
				},
			},
			{
				path: "/second/second",
				name: "secondIndex",
				component: () => import("@/views/second/second.vue"),
				meta: {
					keepAlive: false,
					title: "第二个"
				},
			},
			{
				path: "/third/third",
				name: "thirdIndex",
				component: () => import("@/views/third/third.vue"),
				meta: {
					keepAlive: false,
					title: "第三个"
				},
			},
			{
				path: "/personal/personal",
				name: "personalIndex",
				component: () => import("@/views/personal/personal.vue"),
				meta: {
					keepAlive: false,
					title: "第三个"
				},
			},
		],
	},
	{
		path: "/home/repairs",
		name: "repairs",
		meta: {
			keepAlive: false,
			title: "报修/报事"
		},
		component() {
			return import("@/views/home/repairs.vue");
		},
	},
	{
		path: "/home/complaint",
		name: "complaint",
		meta: {
			keepAlive: false,
			title: "投诉/建议"
		},
		component() {
			return import("@/views/home/complaint.vue");
		},
	},
	{
		path: "/serve/serve-list",
		name: "serve",
		meta: {
			keepAlive: false,
			title: "我的服务"
		},
		component() {
			return import("@/views/serve/serve-list.vue");
		},
	},
	{
		path: "/serve/serve-details",
		name: "serve-details",
		meta: {
			keepAlive: false,
			title: "我的报修"
		},
		component() {
			return import("@/views/serve/serve-details.vue");
		},
	},
	{
		path: "/serve/evaluateAdd",
		name: "serveEvaluateAdd",
		meta: {
			keepAlive: false,
			title: "报修评价"
		},
		component() {
			return import("@/views/serve/evaluateAdd.vue");
		},
	},
	{
		path: "/notice/list",
		name: "noticeList",
		meta: {
			keepAlive: false,
			title: "通知资讯"
		},
		component() {
			return import("@/views/notice/list.vue");
		},
	},
	{
		path: "/notice/details",
		name: "noticeDetails",
		meta: {
			keepAlive: false,
			title: "详情"
		},
		component() {
			return import("@/views/notice/details.vue");
		},
	},
	{
		path: "/home/clear",
		name: "/homeClear",
		meta: {
			keepAlive: false,
			title: "清除缓存"
		},
		component() {
			return import("@/views/home/clear.vue");
		},
	},
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});
router.beforeEach(async function(to, from, next) {
	document.title = to.meta.title || "后勤平台";
	// console.log("to Page", to);
	// console.log("from Page", from);
	if (to.path.toLocaleLowerCase().indexOf("login") >= 0) {
		next();
	} else {
		const appid = localStorage.getItem("appid");
		if (appid) {
			checkLogin(next, appid);
		} else {
			checkAppid(next);
		}
	}
});

/**
 * 重写路由的push方法,,,不重写有报错。百度的方法。
 * 解决vue项目路由出现message: "Navigating to current location (XXX) is not allowed"的问题
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch((error) => error);
};

/**
 * ① 先调用 http://8.142.113.91:9300/room/WxPublicAccounts/getAppidByTenantId.api?tenantId=000000 （get请求） 获取appid
 * ②根据appid 换取 code
 * ③拿到code 和 租户id 去登录
 * http://192.168.0.188:8081/?tenantId=000000&abc=123#/home/home
 */
async function checkAppid(next) {
	const tenantId = getQueryVariable("tenantId");
	await getAppidByTenantId({
		tenantId
	}).then(async (res) => {
		if (res.statusCode == 200) {
			localStorage.setItem("appid", res.data.wechatAppId);
			await checkLogin(next, res.data.wechatAppId);
		}
	});
}

async function checkLogin(next, appid) {
	const token = localStorage.getItem("token");
	const tenantId = getQueryVariable("tenantId");
	localStorage.setItem("tenantId", tenantId);

	if (!token) { //如果token 不存在
		const code = getQueryVariable("code");
		const openid = VueCookies.get("openId");
		if (openid) { //如果 openId 存在			
			await loginByOpenId(next, openid);
		} else if (code) { //如果code存在			
			await wxLogin({
				code,
				tenantId
			}).then(async function(res) {
				if (res.code == 200) {
					const openid = res.data.openid;
					localStorage.setItem("access_token", res.data.access_token);
					localStorage.setItem("refresh_token", res.data.refresh_token);
					localStorage.setItem("scope", res.data.openid.scope);
					VueCookies.set("openId", openid); //return this
					await loginByOpenId(next, openid);
				} else {
					Toast.fail({
						type: "danger",
						message: res.message || "操作失败"
					});
					//学生信息不存在，就不往下走了
					//next();
				}
			});
		} else { //code不存在
			const url = encodeURIComponent(window.location.href);
			window.location.href =
				`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123&tenantId=${tenantId}#wechat_redirect`;
		}
	} else {
		next();
	}
}

async function loginByOpenId(next, openid) {
	const params = {
		tenantId: localStorage.getItem("tenantId"),
		openId: openid
	};
	await login(params).then((result) => {
		if (result.code == 200) {
			var isLoginView = window.location.href.toLocaleLowerCase().indexOf("/login") >= 0;
			if (result.data.NEW_USER) { //如果是新用户				
				next({
					name: "Login"
				});
				// Router.replace({name: 'Login'})
				// next(false)
			} else {
				localStorage.setItem("token", result.data.token);
				localStorage.setItem("userInfo", JSON.stringify(result.data.userInfo));
				if (isLoginView) { //当前是登录页面，跳转到首页
					next({
						name: "homeIndex",
					});
				} else { //当前不是登录页面，不跳转

				}
			}
			next();
		}
	});
}

// 获取url的参数
function getQueryVariable(variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		if (pair[0] == variable) {
			return pair[1];
		}
	}
	return false;
}

export default router;
