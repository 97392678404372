<template>
	<div id="app">
		<!-- 内容区 -->
		<router-view />
	</div>
</template>

<script>
// import { wxLogin } from '@/api/login.js'
export default {
	name: 'App',
	created() {	
		//三级就跳不动了 先注销掉
		// history.pushState(null, null, document.URL);
		// window.addEventListener('popstate', function() {
		// 	history.pushState(null, null, document.URL);
		// });
	},
	methods: {}
};
</script>

<style></style>
