import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";
// Vue.use(axios);
Vue.prototype.axios = axios;

// vant
import Vant from "vant";
import {
	Lazyload
} from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
Vue.use(Lazyload);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import "@/assets/style/base.scss";

Vue.config.productionTip = true;

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app");
