import axios from "axios";
import { Dialog, Toast } from "vant";


// 正式的
const baseURL = '/api'
const SERVER_IP = "192.168.192.129";

// //测试的
// const baseURL = 'https://hy.zhuotone.cn/hy-api'
// const SERVER_IP = "192.168.192.129";

//const EXCLUDE_SERVER_IP = "192.168.192.1(38|34|37)";

const request = axios.create({
  baseURL: baseURL,
  timeout: 16000,
  headers: {
    token: localStorage.getItem("token"),
    SERVER_IP,
    // EXCLUDE_SERVER_IP,
  },
});


const requestForm = axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {
    "Content-Type": "multipart/form-data",
    token: localStorage.getItem("token"),
    SERVER_IP,
    // EXCLUDE_SERVER_IP,
  },
});

// 响应拦截器
request.interceptors.request.use(
  (config) => {
    config.headers["token"] = localStorage.getItem("token");
    config.headers["SERVER_IP"] = SERVER_IP;
    //config.headers["EXCLUDE_SERVER_IP"] = EXCLUDE_SERVER_IP;

    return config;
  },
  (err) => {
    console.log(err);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (res) => {
    // console.log(res);
    // 未设置状态码则默认成功状态
    const code = res.data.status || 200;
    // hideLoading()
    // 获取错误信息
    // const msg = errorCode[code] || res.data.msg || errorCode.default
    if (code === 401) {
      Dialog.confirm({
        title: "系统提示",
        message: "登录状态已过期",
      })
        .then(() => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          location.reload();
        })
        .catch(() => {
          // on cancel
        });
    } else if (code === 401) {
      Toast.success(res.data.message);
      // location.href = '/401'
    } else if (code === 404) {
      location.href = "/404";
    } else if (code === 500) {
      // Message({
      //   message: msg,
      //   type: 'error'
      // })
      return res.data;
    } else if (code !== 200) {
      // Notification.error({
      //   title: msg
      // })
      return res.data;
    } else {
      return res.data;
    }
  },
  (error) => {
    // hideLoading()
    // console.log("err" + error);
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

export { request, requestForm };
