import { request } from "@/utils/request";
// let res = await getBaseList('/system/dicSet/getDicItemByCode',{code:dictCode});
// return res;
const loginApi = {
  login: "/room/WxPublicAccounts/login.api",
  wxLogin: "/room/WxPublicAccounts/getOpenIdByCode.api", // code 换 openid //
  initDictOptions: "/system/dicSet/getDicItemByCode",
  getCode: "/room/WxPublicAccounts/sendCheckCode.api",
  bind: "/room/WxPublicAccounts/bindUser.api",
  getAppidByTenantId: "/room/WxPublicAccounts/getAppidByTenantId.api",
};
// http://8.142.113.91:9300/room/WxPublicAccounts/getAppidByTenantId.api?tenantId=000000

export function login(params) {
  return request({
    url: loginApi.login,
    method: "POST",
    data: params,
  });
}
export function wxLogin(params) {
  return request({
    url: loginApi.wxLogin,
    method: "GET",
    params,
  });
}

export function initDictOptions(dictCode) {
  return request({
    url: loginApi.initDictOptions + "?code=" + dictCode,
    method: "POST",
  });
}
export function getCode(params) {
  return request({
    url: loginApi.getCode,
    method: "POST",
    data: params,
  });
}
export function bindUser(params) {
  return request({
    url: loginApi.bind,
    method: "POST",
    data: params,
  });
}
export function getAppidByTenantId(params) {
  return request({
    url: loginApi.getAppidByTenantId,
    method: "GET",
    params,
  });
}
